import React from 'react';
import cn from 'classnames';
import Text from 'components/text/text';
import * as Title from 'components/title/title.jsx';
import LocaleString from 'components/locale-string/locale-string.jsx';
import {useAppContext} from 'components/../app-context.js';
import {usePaymentContext} from 'components/payment/payment-context';
import {useGetTariffName} from 'common/hooks';
import * as AppEnv from 'app-env';
import Wrapper from 'components/wrapper/wrapper';

interface Option {
  firstOption: AppEnv.TariffOption;
  option: AppEnv.TariffOption;
}

const Option = ({firstOption, option}: Option) => {
  const {user} = useAppContext();
  const {client, selectedTariffId, setSelectedTariffId} = usePaymentContext();

  const getTariffName = useGetTariffName();

  const isDiscount = user.is_review === 2 && !client.isCustomer;
  const amount = isDiscount ? option.summa_review : option.summa;
  const first_summa = isDiscount ? firstOption.summa_review : firstOption.summa;

  return (
    <div
      className={cn('tariff-plan__options-item', {
        'tariff-plan__options-item_selected': option.id === selectedTariffId
      })}
      onClick={() => setSelectedTariffId(option.id)}
    >
      <Title.H3 color="green" style={{marginBottom: 8}}>
        {getTariffName(option)}
      </Title.H3>

      <Title.H2>
        <LocaleString
          amount={amount}
          type="currency"
          currency={option.currency}
        />
      </Title.H2>

      {option.quantity == 6 ? (
        <Wrapper gap={4} alignItems="center" noWrap>
          <Text style={{textDecoration: 'line-through'}}>
            <LocaleString
              amount={+first_summa * 6}
              type="currency"
              currency={option.currency}
            />
          </Text>

          <Text isSmall className="tariff-plan__discount" color="white">
            {'-10%'}
          </Text>
        </Wrapper>
      ) : (
        option.quantity == 12 && (
          <Wrapper gap={4} alignItems="center" noWrap>
            <Text style={{textDecoration: 'line-through'}}>
              <LocaleString
                amount={+first_summa * 12}
                type="currency"
                currency={option.currency}
              />
            </Text>

            <Text className="tariff-plan__discount" isSmall color="white">
              {'-25%'}
            </Text>
          </Wrapper>
        )
      )}
    </div>
  );
};

export default Option;
