import React, {useState} from 'react';
import update from 'immutability-helper';
import {t} from 'ttag';

import {TableCell} from '../../../../../../../components/table/table.jsx';
import Button from '../../../../../../../components/button/button';
import Icons from '../../../../../../../components/icons/icons.jsx';
import Editor from '../../../editor/editor.jsx';
import Confirmation from '../../../../../../../components/confirmation/confirmation.jsx';
import {useAppContext} from '../../../../../../../app-context.js';
import {useEmployeeContext} from '../employee-context.js';
import {useRequest} from '../../../../../../../common/hooks';
import {
  updateEmployeeReq,
  deleteEmployeeReq
} from '../../../../../../../common/api/api.js';

const Actions = () => {
  const {employeeList, setEmployeeList, user} = useAppContext();
  const {employee} = useEmployeeContext();

  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const {request} = useRequest();
  const index = employeeList.findIndex(item => item.id === employee.id);

  const handleUpdate = async values => {
    setIsEditorOpen(false);

    const {access_instances} = values;

    await request(
      updateEmployeeReq(
        user.id,
        employee.id,
        user.crm == 'LK' ? values : {access_instances}
      )
    );

    setEmployeeList(
      update(employeeList, {
        [index]: {
          fullname: {$set: values.fullname},
          email: {$set: values.email},
          phone: {$set: values.phone},
          access_instances: {$set: values.access_instances},
          nickname: {$set: values.nickname}
        }
      })
    );

    return true;
  };

  const deleteEmployee = async () => {
    const res = await request(deleteEmployeeReq(user.id, employee.id));

    if (Object.keys(res).length) {
      setEmployeeList(
        update(employeeList, {
          $splice: [[index, 1]]
        })
      );
    }

    return true;
  };

  const initialValues = {
    fullname: employee.fullname || '',
    email: employee.email || '',
    phone: employee.phone || '',
    access_instances: employee.access_instances || '',
    nickname: employee.nickname || ''
  };

  return (
    <>
      <TableCell isIcon>
        <Button color="transparent" onClick={() => setIsEditorOpen(true)}>
          <Icons.Pencil />
        </Button>

        {isEditorOpen && (
          <Editor
            title={t`Edit the staff member`}
            initialValues={initialValues}
            onSubmit={handleUpdate}
            onClose={() => setIsEditorOpen(false)}
          />
        )}
      </TableCell>

      <TableCell isIcon>
        <Button color="transparent" onClick={() => setIsConfirmationOpen(true)}>
          <Icons.Trash />
        </Button>

        {isConfirmationOpen && (
          <Confirmation
            title={t`Are you sure you want to delete this staff member?`}
            onClick={res =>
              res ? deleteEmployee() : setIsConfirmationOpen(false)
            }
          />
        )}
      </TableCell>
    </>
  );
};

export default Actions;
