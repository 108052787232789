import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import update from 'immutability-helper';

import {SingleSelect} from 'components/form/form';
import * as Title from 'components/title/title';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useConnectionListContext} from 'components/connection-list/connection-list-context';
import {useSettingsContext} from '../../../settings-context';

interface PipelineSettings {
  pipe_id?: number;
  status_id?: number;
}

const StatusId = () => {
  const {pipelineList} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {setIsAlertOpen} = useSettingsContext();

  const [field, , helpers] = useField<PipelineSettings[]>('pipeline_settings');

  const handleChange = (value: number) => {
    const pipelineSettings = update(field.value, {
      [0]: {status_id: {$set: value}}
    });

    helpers.setValue(pipelineSettings);
  };

  const handleClick = () =>
    connection.is_limited ? setIsAlertOpen(true) : undefined;

  const {statuses} = pipelineList?.find(
    (item: any) => item.id == field.value[0]?.pipe_id // eslint-disable-line
  ) || {statuses: []};

  // eslint-disable-next-line
  const options = statuses.map((item: any) => ({
    label: item.name,
    value: item.id
  }));

  const {value} =
    options?.find(
      (item: any) => item.value == field.value[0]?.status_id // eslint-disable-line
    ) || {};

  const isDisabled = connection.is_limited || !options.length;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Lead stage`}</Title.H3>

      <SingleSelect
        options={options}
        placeholder={t`Lead stage`}
        value={value}
        isDisabled={isDisabled}
        onChange={handleChange}
        onClick={handleClick}
      />
    </div>
  );
};

export default StatusId;
