import React, {useState, useEffect} from 'react';
import {useParams, Link} from 'react-router-dom';
import moment from 'moment';
import {t} from 'ttag';
import Page from '../../components/page/page.jsx';
import Article from '../../components/article/article.jsx';
import * as Title from '../../components/title/title.jsx';
import Text from '../../components/text/text.jsx';
import Button from '../../components/button/button';
import Icons from '../../components/icons/icons.jsx';
import Preloader from '../../components/preloader/preloader.jsx';
import EmptyBox from '../../components/empty-box/empty-box.jsx';
import {useAppContext} from '../../app-context.js';
import {useRequest} from '../../common/hooks';
import {getNoticeReq} from '../../common/api/api.js';

const Notices = () => {
  const {id} = useParams();
  const {user} = useAppContext();
  const [notice, setNotice] = useState();
  const {request} = useRequest();

  const getNotice = async () => {
    const res = await request(getNoticeReq({crm: user.crm, id}));
    setNotice(res);
  };

  useEffect(() => {
    getNotice();
  }, []);

  const date = moment.unix(notice?.date_start).format('L');

  return (
    <Page>
      <Link
        className="link"
        style={{width: 'max-content', marginBottom: 24, textDecoration: 'none'}}
        to={`/${window.location.search}`}
      >
        <Button color="white">
          <Icons.ArrowLeft />
          {t`Back`}
        </Button>
      </Link>

      {notice ? (
        Object.keys(notice).length ? (
          <Article isWide style={{margin: '0 auto'}}>
            <Title.H1 style={{marginBottom: 16}}>{notice.title}</Title.H1>

            <Text color="dark" isBold style={{marginBottom: 24}}>
              {notice.short_description}
            </Text>

            <div
              style={{marginBottom: 24}}
              dangerouslySetInnerHTML={{__html: notice.description}}
            />

            <Text>{date}</Text>
          </Article>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </Page>
  );
};

export default Notices;
