import React from 'react';

import Wrapper from '../../../wrapper/wrapper.jsx';
import {useConnectionContext} from '../connection-context.js';
import {useConnectionListContext} from '../../connection-list-context.js';

import Id from './id/id.jsx';
import Key from './key/key.jsx';
import PaymentDate from './payment-date/payment-date.jsx';
import PhoneNumber from './phone-number/phone-number.jsx';
import Tariff from './tariff/tariff.jsx';
import Username from './username/username.jsx';
import WebhookUrl from './webhook-url/webhook-url';

const Info = () => {
  const {client} = useConnectionListContext();
  const {connection} = useConnectionContext();

  const isKeyShown = !!(!client.isCustomer && !connection.is_limited);

  const isWebhookUrlShown =
    client.crm == 'PIPEDRIVE' && !client.isCustomer && connection.phone;

  return (
    <Wrapper isColumn>
      <Tariff />
      <PaymentDate />
      <Username />
      <PhoneNumber />
      {isKeyShown && <Key />}
      <Id />
      {isWebhookUrlShown && <WebhookUrl />}
    </Wrapper>
  );
};

export default Info;
