import React from 'react';
import {t} from 'ttag';

import Table, {
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '../../../../../components/table/table.jsx';
import {useAppContext} from '../../../../../app-context.js';
import {useStaffContext} from '../staff-context.js';

import Employee from './employee/employee.jsx';

const EmployeeList = () => {
  const {user} = useAppContext();
  const {visibleEmployeeList} = useStaffContext();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Staff member`}</TableCell>
        <TableCell>{t`Job title`}</TableCell>
        <TableCell>{t`Email`}</TableCell>
        <TableCell>{t`Personal phone number`}</TableCell>
        <TableCell>{t`Available numbers`}</TableCell>

        {user.is_admin > 0 && (
          <>
            <TableCell />
            <TableCell />
          </>
        )}
      </TableHead>

      <TableBody>
        {visibleEmployeeList.map(item => (
          <TableRow key={item.id}>
            <Employee employee={item} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EmployeeList;
