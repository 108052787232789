import React from 'react';
import Option from './option/option';
import {useTariffPlanContext} from '../../tariff-plan-context';

const OptionList = () => {
  const {tariffOptions} = useTariffPlanContext();
  const firstOption = tariffOptions[0];

  return (
    <div className="tariff-plan__options" style={{marginBottom: 16}}>
      {tariffOptions.map(item => (
        <Option firstOption={firstOption} key={item.id} option={item} />
      ))}
    </div>
  );
};

export default OptionList;
