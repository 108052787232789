import React from 'react';
import PropTypes from 'prop-types';
import {useAppContext} from '../../app-context.js';

const LocaleString = ({amount, type, currency}) => {
  const {lang} = useAppContext();

  const decimalPart = parseFloat(amount) % 1;
  const maximumFractionDigits = decimalPart === 0 ? 0 : 2;

  return (
    <>
      {parseFloat(amount).toLocaleString(lang, {
        currency,
        maximumFractionDigits,
        style: type
      })}
    </>
  );
};

LocaleString.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.string,
  currency: PropTypes.string,
  type: PropTypes.string
};

LocaleString.defaultProps = {
  amount: 0,
  type: 'decimal',
  currency: 'RUB'
};

export default LocaleString;
