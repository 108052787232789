import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';
import {useEditorContext} from '../editor-context.js';
import Attachment from './attachment/attachment.jsx';
import Wrapper from '../../../../../components/wrapper/wrapper.jsx';
import EmptyBox from 'components/empty-box/empty-box.jsx';
import Preloader from '../../../../../components/preloader/preloader.jsx';

const AttachmentList = ({isLoad}) => {
  const {messageTemplate, attachmentList} = useEditorContext();

  const isShown = attachmentList?.length > 0 || messageTemplate.file_count > 0;

  return (
    <>
      {isShown && (
        <>
          {attachmentList ? (
            attachmentList.length > 0 ? (
              <Wrapper gap={4} isColumn>
                {attachmentList.map((item, i) => (
                  <Attachment
                    key={i}
                    attachment={item}
                    index={i}
                    isLoad={isLoad}
                  />
                ))}
              </Wrapper>
            ) : (
              <EmptyBox title={t`Attached files are being processed`} />
            )
          ) : (
            <Preloader />
          )}
        </>
      )}
    </>
  );
};

AttachmentList.propTypes = {
  isLoad: PropTypes.bool
};

export default AttachmentList;
