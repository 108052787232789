import React, {useEffect, useState} from 'react';

import ErrorPage from 'components/error-page/error-page.jsx';
import Page from 'components/page/page.jsx';
import Preloader from 'components/preloader/preloader.jsx';
import ProductsSection from 'components/products-section/products-section.jsx';
import {useAppContext} from '../../app-context.js';
import {useRequest} from '../../common/hooks';
import {getConnectionCondition} from '../../common/actions.js';
import {getDashboardReq} from '../../common/api/api.js';

import Automation from './automation/automation.jsx';
import Connections from './connections/connections.jsx';
import DenyList from './deny-list/deny-list.jsx';
import HomeContext from './home-context.js';
import NoticeList from './notice-list/notice-list.jsx';
import Partners from './partners/partners.jsx';
import Trial from './trial/trial';

const Home = () => {
  const {user, lang, isForbidden, setUser, setIsForbidden} = useAppContext();

  const [connectionList, setConnectionList] = useState();
  const [noticeList, setNoticeList] = useState();
  const [messageTemplateList, setMessageTemplateList] = useState();

  const {request} = useRequest();

  const getDashboard = async () => {
    const res = await request(
      getDashboardReq(
        user.crm,
        user.domain,
        lang,
        user.crm_param_id,
        user.telphin_code,
        user.token
      )
    );

    if (Object.keys(res).length) {
      const {
        instances,
        integration,
        notifications,
        partner = {},
        templates = []
      } = res;

      const instanceList = Object.entries(instances).map(item => ({
        id: parseInt(item[0]),
        ...item[1],
        ...getConnectionCondition(item[1])
      }));

      const tempUser = {...user, ...integration, partner};

      setUser(tempUser);
      setNoticeList(notifications);
      setMessageTemplateList(templates);
      setConnectionList(instanceList);

      return;
    }

    setIsForbidden(true);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  return (
    <HomeContext.Provider
      value={{
        connectionList,
        messageTemplateList,
        setConnectionList,
        setMessageTemplateList
      }}
    >
      {isForbidden ? (
        <ErrorPage title="403 Forbidden" />
      ) : connectionList ? (
        <div className="home">
          <NoticeList
            noticeList={noticeList}
            isImportant
            setNoticeList={setNoticeList}
          />
          {user.date_trial > Date.now() / 1000 && <Trial />}
          <Connections />
          <Automation />
          {user.crm != 'AVITO' && <DenyList />}
          {user.crm !== 'MONDAY' && user.crm != 'TGMONDAY' && (
            <ProductsSection />
          )}

          <Partners />
          {/* <NoticeList noticeList={noticeList} setNoticeList={setNoticeList} /> */}
        </div>
      ) : (
        <Page isCenter isTransparent>
          <Preloader />
        </Page>
      )}
    </HomeContext.Provider>
  );
};

export default Home;
