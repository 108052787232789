import React, {useState, useEffect} from 'react';
import {Formik, Form} from 'formik';
import * as yup from 'yup';
import {t} from 'ttag';

import Name from '../../../../../name/name.jsx';
import * as Title from '../../../../../../../title/title.jsx';
import Text from '../../../../../../../text/text.jsx';
import Line from '../../../../../../../line/line.jsx';
import Wrapper from '../../../../../../../wrapper/wrapper.jsx';
import Button, {SaveButton} from '../../../../../../../button/button';
import {useConnectionContext} from '../../../../../connection-context.js';
import {useRequest} from '../../../../../../../../common/hooks';
import {
  getInstanceStateReq,
  checkAuthenticationCodeReq,
  requestQrCodeAuthenticationReq,
  getConnectionStatusReq
} from '../../../../../../../../common/api/api.js';

import Code from './code/code.jsx';

const TelegramConfirmation = () => {
  const {connection} = useConnectionContext();

  const [isDisabled, setIsDisabled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();

  const {request} = useRequest();

  const getConnectionState = async () => {
    const res = await request(getInstanceStateReq(connection.chat_key));
    setPhoneNumber(res.codeInfo?.phoneNumber);
  };

  useEffect(() => {
    getConnectionState();
  }, []);

  const handleSubmit = async values => {
    const res = await request(
      checkAuthenticationCodeReq(connection.chat_key, values.code)
    );
    if (res?._) setIsDisabled(true);
    return true;
  };

  const handleClick = async () => {
    setIsFetching(true);
    await request(requestQrCodeAuthenticationReq(connection.chat_key));

    await request(
      getConnectionStatusReq({chat_key: connection.chat_key, full: 1})
    );

    setIsFetching(false);
  };

  const initialValues = {
    code: ''
  };

  const validationSchema = yup.object().shape({
    code: yup.string().required()
  });

  return (
    <>
      <Name style={{marginBottom: 16}} />

      <Title.H2 style={{marginBottom: 8}}>
        {t`Enter the confirmation code`}
      </Title.H2>

      <Text style={{marginBottom: 8}}>
        {t`Telegram has sent a confirmation code to your phone number +${phoneNumber}.`}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Code />
          <Line />

          <Wrapper>
            <SaveButton isDisabled={isDisabled}>{t`Continue`}</SaveButton>

            <Button color="white" isDisabled={isFetching} onClick={handleClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </>
  );
};

export default TelegramConfirmation;
