import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import PhoneCodeEditor from '../components/phone-code-editor/phone-code-editor';

import {useAppContext} from '../../../../../app-context.js';
import {useRequest} from '../../../../../common/hooks';
import {
  deleteDialingCodeReq,
  getDialingCodeReq,
  updateDialingCodeReq
} from '../../../../../common/api/bitrix24.js';

const Bitrix24 = () => {
  const {user, setNotificationList} = useAppContext();
  const [dialingCode, setDialingCode] = useState();
  const {crmRequest} = useRequest();

  const getDialingCode = async () => {
    const res = await crmRequest({
      crm: user.crm,
      ...getDialingCodeReq(user.domain)
    });

    const pc =
      typeof res == 'string'
        ? res
        : typeof res?.data == 'string'
          ? res.data
          : '';

    setDialingCode(pc);
  };

  useEffect(() => {
    getDialingCode();
  }, []);

  const handleSubmit = async values => {
    const code = values.phoneCode.trim();

    const res = await crmRequest({
      crm: user.crm,
      ...(code
        ? updateDialingCodeReq({domain: user.domain, code})
        : deleteDialingCodeReq(user.domain))
    });

    if (typeof res === 'string') {
      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: res.length
                ? t`The phone code has been updated`
                : t`The phone code has been removed`
            }
          ]
        })
      );
    }

    return true;
  };

  return <PhoneCodeEditor phoneCode={dialingCode} onSubmit={handleSubmit} />;
};

export default Bitrix24;
