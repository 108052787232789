import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import {TableCell} from '../../../../../../components/table/table.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useHomeContext} from '../../../../home-context.js';

import Actions from './actions/actions.jsx';
import EmployeeContext from './employee-context.js';

const Employee = ({employee}) => {
  const {user} = useAppContext();
  const {connectionList} = useHomeContext();

  const accessInstances = employee.access_instances
    ? JSON.parse(employee.access_instances)
    : [];

  const availableConnectionIdList = accessInstances.filter(item =>
    connectionList.find(connection => connection.phone == item)
  );

  const firstAvailableConnection = availableConnectionIdList?.[0];

  return (
    <EmployeeContext.Provider value={{employee}}>
      <TableCell>{employee.fullname}</TableCell>
      <TableCell>{employee.nickname}</TableCell>
      <TableCell>{employee.email}</TableCell>
      <TableCell>{employee.phone}</TableCell>

      <TableCell>
        {accessInstances.length
          ? firstAvailableConnection && (
              <>
                {`+${firstAvailableConnection}`}

                {availableConnectionIdList.length > 1 &&
                  ` + ${availableConnectionIdList.length - 1}`}
              </>
            )
          : t`All`}
      </TableCell>

      {user.is_admin > 0 && <Actions />}
    </EmployeeContext.Provider>
  );
};

Employee.propTypes = {
  employee: PropTypes.shape({
    access_instances: PropTypes.string,
    access_token: PropTypes.string,
    amo_id: PropTypes.number,
    amojo_id: PropTypes.string,
    crm_user_id: PropTypes.number,
    email: PropTypes.string,
    fullname: PropTypes.string,
    id: PropTypes.number,
    instance_id: PropTypes.number,
    integration_id: PropTypes.number,
    is_deleted: PropTypes.number,
    lang: PropTypes.string,
    phone: PropTypes.string,
    refresh_token: PropTypes.string,
    uuid: PropTypes.string,
    nickname: PropTypes.string
  })
};

export default Employee;
