import React, {useState, useEffect} from 'react';
import {Formik, Form} from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {t} from 'ttag';

import * as Title from '../title/title.jsx';
import Article from '../article/article.jsx';
import Button, {SaveButton} from '../button/button';
import Line from '../line/line.jsx';
import Text from '../text/text.jsx';
import Wrapper from '../wrapper/wrapper.jsx';
import {useRequest} from '../../common/hooks';
import {
  getInstanceStateReq,
  checkAuthenticationPassword,
  requestQrCodeAuthenticationReq,
  getConnectionStatusReq
} from '../../common/api/api.js';

import Password from './password/password.jsx';

const TelegramAuthentication = ({chat_key, connectionName, passwordHint}) => {
  const [hint, setHint] = useState(passwordHint);

  const [isDisabled, setIsDisabled] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {request} = useRequest();

  const getConnectionState = async () => {
    const res = await request(getInstanceStateReq(chat_key));
    setHint(res.passwordHint);
  };

  useEffect(() => {
    getConnectionState();
  }, []);

  const handleSubmit = async values => {
    const password = values.password.trim();
    const res = await request(
      checkAuthenticationPassword({chat_key, password})
    );
    if (res?._) setIsDisabled(true);
    return true;
  };

  const handleClick = async () => {
    setIsFetching(true);
    await request(requestQrCodeAuthenticationReq(chat_key));
    await request(getConnectionStatusReq({chat_key, full: 1}));
    setIsFetching(false);
  };

  const initialValues = {password: ''};

  const validationSchema = yup.object().shape({
    password: yup.string().trim().required()
  });

  return (
    <>
      {connectionName}

      <Article>
        <Title.H2 style={{marginBottom: 8}}>{t`Enter the password`}</Title.H2>

        <Text style={{marginBottom: 8}}>
          {t`You have Two-Step Verification enabled, so your account is protected with an additional password.`}
        </Text>
      </Article>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Password passwordHint={hint} />
          <Line />

          <Wrapper>
            <SaveButton isDisabled={isDisabled}>{t`Continue`}</SaveButton>

            <Button color="white" isDisabled={isFetching} onClick={handleClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </>
  );
};

TelegramAuthentication.propTypes = {
  chat_key: PropTypes.string,
  connectionName: PropTypes.node,
  passwordHint: PropTypes.string
};

TelegramAuthentication.defaultProps = {
  chat_key: '',
  connectionName: <></>,
  passwordHint: ''
};

export default TelegramAuthentication;
